import Vue from 'vue';
import Router from 'vue-router';

import store from '@/app/store';

import { trackPageView } from '@/app/services/ArFortyTwo';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            meta: { pageType: 'deelnemer-pagina' },
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/form',
            name: 'FormPage',
            component: () =>
            {
                return import( /* webpackChunkName: "formpage" */ '@/app/pages/FormPage' );
            }
        },
        {
            path: '/kies',
            name: 'Kies',
            component: () =>
            {
                return import( /* webpackChunkName: "kies" */ '@/app/pages/Kies' );
            }
        },

        {
            path: '/quiz',
            name: 'Quiz',
            component: () =>
            {
                return import( /* webpackChunkName: "quiz" */ '@/app/pages/quiz/Game' );
            }
        },

        {
            path: '/kofferspel',
            name: 'Kofferspel',
            component: () =>
            {
                return import( /* webpackChunkName: "kofferspel" */ '@/app/pages/kofferspel/Game' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                store.dispatch( 'kofferspel/reset' );
                next();
            }
        },

        {
            path: '/quiz/uitleg',
            name: 'QuizUitleg',
            component: () =>
            {
                return import( /* webpackChunkName: "quiz-uitleg" */ '@/app/pages/quiz/Explain' );
            }
        },

        {
            path: '/kofferspel/uitleg',
            name: 'KofferspelUitleg',
            component: () =>
            {
                return import( /* webpackChunkName: "kofferspel-uitleg" */ '@/app/pages/kofferspel/Explain' );
            }
        },

        {
            path: '/Quiz/result',
            name: 'QuizResult',
            component: () =>
            {
                return import( /* webpackChunkName: "quiz-result" */ '@/app/pages/quiz/Result' );
            }
        },

        {
            path: '/kofferspel/result',
            name: 'KofferspelResult',
            component: () =>
            {
                return import( /* webpackChunkName: "kofferspel-result" */ '@/app/pages/kofferspel/Result' );
            }
        },

        {
            path: '/gegevensupdate',
            name: 'FormUpdate',
            meta: { pageType: 'deelnemer-pagina' },
            component: () =>
            {
                return import( /* webpackChunkName: "FormUpdate" */ '@/app/pages/FormUpdate' );
            }
        },

        {
            path: '/bedankt',
            name: 'Bedankt',
            meta: { pageType: 'deelnemer-pagina-conversie' },
            component: () =>
            {
                return import( /* webpackChunkName: "bedankt" */ '@/app/pages/Bedankt' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    console.log( 'route', to.path );
    trackPageView( to.path, to.meta.pageType );

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
