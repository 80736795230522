import quizData from '@/app/data/quiz.json';
import mixin from '@/app/mixins/shuffle';

export default {
    namespaced: true,

    state: {
        quizData: mixin.methods.shuffle( quizData ),
        result: {}
    },

    getters: {
        questions: state => state.quizData,
        result: state => state.result
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.result = Object.assign({}, state.result, payload );
        }
    },
    actions: {
        result: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        }
    }
};
