function trackPageView( path, pageType )
{
    pageType = pageType || 'overig';
    if( window.gdl )
    {
        window.gdl( 'event', {
            'type': 'navigation',
            'navigation': {
                'productBrand': 'npl',
                'platformSiteType': 'campaign',
                'pageType': pageType,
                'url_path': path
            },
            'data': window.gdlDataY
        });

        window.gdl( 'event', {
            type: 'page',
            type_event: 'pageview',
            data: {
                label: path
            }
        });
    }
    if( window.gdl )
    {
        window.gdl.stapname = path;
    }
}

function trackEvent( category, action, label )
{
    window.gdl( 'event', {
        type: 'userAction',
        data: {
            category, // veld type
            action, // event type
            label // id van het veld
        }
    });
    /*
        'category':'text', // veld type
        'action':'focus', // event type
        'label':'initials' //id van het veld
    */
    /* let ipType = el.getAttribute( 'type' ) ? el.getAttribute( 'type' ) : 'link';
    ipType = ipType === 'email' ? 'text' : ipType;
    try
    {
        window.gdl( 'event', {
            type: 'interaction',
            interaction: {
                identification: {
                    unique_identifier_type: 'data-gdl-type', // waar unIdT de id type is – liefst data-gdl-type of zo niet dan id of name of class
                    unique_identifier: el.dataset.gdlType, // waar unId de element id is – liefst worden de juiste data-gdl-type attributes gebruikt per element
                    title: el.dataset.gdlType,
                    area: '',
                    type: ipType,
                    parent_form: {
                        unique_identifier_type: 'id',
                        unique_identifier: 'no form id', // ik zie geen ‘form’ tag dus ik neem aan dat er geen id hier gegeven kan worden
                        title: 'no form title', // ik zie geen ‘form’ tag dus ik neem aan dat er geen title hier gegeven kan worden
                        area: '',
                        type: 'form',
                        parent_form: 'no_parent_form'
                    },
                    element_source: window.gdl.stapname === '' ? window.location.pathname : window.gdl.stapname
                },
                action: type // dus focus of click etc
            },
            element_target: typeof el.getAttribute( 'href' ) !== 'undefined' ? el.getAttribute( 'href' ) : ''
        });
        console.log( 'interaction event: ' + type + ', triggered for ' + el.dataset.gdlType, ipType );
        return;
    }
    catch( e )
    {
        console.log( 'interaction event: ' + type + ', failed to trigger for ' + el.dataset.gdlType + ': ' + e.message );
    } */
}

function trackError( el, message )
{
    try
    {
        window.gdl( 'event', {
            type: 'error',
            error: {
                identification: {
                    unique_identifier_type: 'data-gdl-type',
                    unique_identifier: el.dataset.gdlType,
                    title: message, // de foutmelding tekst voor de element van de error
                    area: '',
                    type: el.getAttribute( 'type' ),
                    parent_form: {
                        unique_identifier_type: 'id',
                        unique_identifier: 'no form id', // ik zie geen ‘form’ tag dus ik neem aan dat er geen id hier gegeven kan worden
                        title: 'no form title', // ik zie geen ‘form’ tag dus ik neem aan dat er geen title hier gegeven kan worden                                                   'area': '',
                        type: 'form',
                        parent_form: 'no_parent_form'
                    },
                    element_source: window.gdl.stapname === '' ? window.location.pathname : window.gdl.stapname // zie boven bij losse pageviews van wijziging van gdl.stapname
                },
                message: el.dataset.gdlType + ' | ' + message // de foutmelding tekst voor de element van de error
            }
        });
        console.log( 'input error event triggered for ' + el.dataset.gdlType + ' message:' + message );
    }
    catch( e )
    {
        console.log( 'input error event failed to trigger for ' + el.dataset.gdlType + ': ' + e.message );
    }
}

function trackSubmit( form )
{
    try
    {
        window.gdl( 'event', {
            type: 'interaction',
            interaction: {
                identification: {
                    unique_identifier_type: 'data-gdl-type',
                    unique_identifier: form.dataset.gdlType, // jullie gebruiken geen form tag volgens mij dus graag iets relevants voor verzinnen bv “game” of “registratie”
                    title: form.dataset.gdlType, // jullie gebruiken geen form tag volgens mij dus graag iets relevants voor verzinnen bv “game” of “registratie”
                    area: 'middle',
                    type: 'form',
                    parent_form: 'no_parent_form',
                    element_source: window.gdl.stapname === '' ? window.location.pathname : window.gdl.stapname // hier gebruiken wij de location.pathname van de gesubmit pagina – omdat dit site een spi is kan je beter de gdl.stapname hier gebruiken waarschijnlijk
                },
                action: {
                    status: 'success',
                    message: ''
                }
            },
            element_target: window.gdl.stapname === '' ? window.location.pathname : window.gdl.stapname // hier gebruiken wij de location.pathname van de gesubmit pagina – omdat dit site een spi is kan je beter de gdl.stapname hier gebruiken waarschijnlijk
        });
        console.log( 'submit event (status:sucess) triggered for ' + form.dataset.gdlType );
    }
    catch( e )
    {
        console.log( 'submit event (status: success) failed to trigger for ' + form.dataset.gdlType + ' : ' + e.message );
    }
}

export {
    trackPageView,
    trackEvent,
    trackError,
    trackSubmit
};
