import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `${obj.label} is niet geldig`
            },
            number: {
                min: obj => `${obj.path} moet minstens ${obj.min} zijn`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                zipcode: {
                    placeholder: 'Postcode',
                    name: 'zipcode',
                    tabindex: 1
                },

                house_num: {
                    name: 'house_num',
                    type: 'number',
                    placeholder: 'Huisnr',
                    min: 0,
                    tabindex: 2
                },

                house_num_add: {
                    name: 'house_num_add',
                    placeholder: 'Toev.',
                    tabindex: 3
                },

                iban: {
                    name: 'iban',
                    placeholder: 'Laatste 4 cijfers van uw IBAN',
                    tabindex: 4,
                    maxlength: 4
                },

                emailaddress: {
                    name: 'emailaddress',
                    placeholder: 'Emailadres',
                    type: 'email',
                    tabindex: 2
                },

                telephone: {
                    name: 'telephone',
                    placeholder: 'Telefoonnummer',
                    type: 'tel',
                    tabindex: 1
                },

                kit: {
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox',
                    tabindex: 14
                }
            },

            validationSchema: yup.object().shape({
                zipcode: yup.string()
                    .label( 'postcode' )
                    .required()
                    .matches( /^[1-9][0-9]{3} {0,1}[A-Za-z]{2}$/ ),
                house_num: yup.number()
                    .label( 'huisnummer' )
                    .nullable()
                    .required()
                    .min( 1 ),
                house_num_add: yup.string()
                    .label( 'toevoeging' )
                    /* eslint-disable-next-line */
                    .matches( /^([A-z0-9\-])+$/, { excludeEmptyString: true })
                    .max( 10 ),
                iban: yup.string()
                    .label( 'IBAN' )
                    .required()
                    .test( 'len', 'Dit is geen geldige IBAN', val => val.length === 4 )
            }),

            validationSchemaUpdate: yup.object().shape({
                emailaddress: yup.string()
                    .label( 'e-mail' )
                    .nullable()
                    .email( 'Dit is geen geldig mailadres' ),
                telephone: yup.string()
                    .label( 'telefoonnummer' )
                    .nullable()
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/, { excludeEmptyString: true })
            })
        };
    }
};
