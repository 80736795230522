import {
    trackEvent,
    trackError,
    trackSubmit,
    trackPageView
} from '@/app/services/ArFortyTwo';

export default {
    methods: {
        trackEvent,
        trackError,
        trackSubmit,
        trackPageView
    }
};
