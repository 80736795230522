var shuffle = function( array )
{
    for( var i = array.length - 1; i > 0; i-- )
    {
        var j = Math.floor( Math.random() * ( i + 1 ) );
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
};

export default {
    namespaced: true,

    state: {
        koffers: [],
        score: 10000,
        pickedKoffer: null,
        recentlyOpened: null
    },

    getters: {
        koffers: state => state.koffers,
        pickedKoffer: state => state.pickedKoffer,
        openedKoffers: state =>
        {
            return state.koffers.filter( koffer => koffer.open ).length;
        },
        unopenedKoffers: state =>
        {
            return state.koffers.filter( koffer => !koffer.open );
        },
        score: state => state.score,
        recentlyOpened: state => state.recentlyOpened
    },

    mutations: {
        pickKoffer: ( state, payload ) =>
        {
            state.pickedKoffer = payload;
        },

        openedKoffer: ( state, payload ) =>
        {
            // state.openedKoffers.push( payload );
            state.koffers[ payload.index ].open = true;
            state.recentlyOpened = state.koffers[ payload.index ].prize;
        },

        saveScore: ( state, payload ) =>
        {
            state.score = payload;
        },

        reset: state =>
        {
            let newSetKoffers = [
                { prize: 0.01, open: false },
                { prize: 0.2, open: false },
                { prize: 0.5, open: false },
                { prize: 1, open: false },
                { prize: 5, open: false },
                { prize: 10, open: false },
                { prize: 20, open: false },
                { prize: 50, open: false },
                { prize: 100, open: false },
                { prize: 500, open: false },
                { prize: 1000, open: false },
                { prize: 2500, open: false },
                { prize: 5000, open: false },
                { prize: 10000, open: false },
                { prize: 25000, open: false },
                { prize: 50000, open: false },
                { prize: 100000, open: false },
                { prize: 200000, open: false },
                { prize: 300000, open: false },
                { prize: 400000, open: false },
                { prize: 500000, open: false },
                { prize: 750000, open: false },
                { prize: 1000000, open: false },
                { prize: 2000000, open: false },
                { prize: 2500000, open: false },
                { prize: 5000000, open: false }
            ];

            shuffle( newSetKoffers );
            state.koffers = newSetKoffers;

            state.openedKoffers = [];
            state.pickedKoffer = null;
        }
    },

    actions: {
        pickKoffer: ( context, payload ) =>
        {
            context.commit( 'pickKoffer', payload );
        },

        openKoffer: ( context, payload ) =>
        {
            context.commit( 'openedKoffer', payload );
        },

        saveScore: ( context, payload ) =>
        {
            context.commit( 'saveScore', payload );
        },

        reset: context =>
        {
            context.commit( 'reset' );
        }
    }
};
