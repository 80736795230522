<template>
    <div class="main-layout">
        <a target="_blank" href="https://www.postcodeloterij.nl/" class="npl-logo" @click="$e => trackEvent( 'image', 'click', 'link/postcodeloterij' )" data-gdl-type="link/postcodeloterij" type="image"><img src="/static/img/npl-logo.png" /></a>
        <a target="_blank" href="https://www.postcodeloterij.nl/hoe-werkt-het/miljoenenjacht" class="mjj-logo" @click="$e => trackEvent( 'image', 'click', 'link/postcodeloterij' )" data-gdl-type="link/postcodeloterij" type="image"><img src="/static/img/miljoenenjacht.png" /></a>
        <div class="content">
            <slot></slot>
        </div>

        <a class="av-footer" href="https://postcodeloterij.nl/actievoorwaarden/2021_miljoenenjachtspel" target="_blank" @click="$e => trackEvent( 'link', 'click', 'actievoorwaarden' )" data-gdl-type="actievoorwaarden">Actievoorwaarden</a>
        <img class="speel-bewust" src="/static/img/speel-bewust.png" />
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { r42 } from '@/app/mixins';
    export default {
        mixins: [ r42 ]
    };
</script>
